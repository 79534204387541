import { Application } from '@hotwired/stimulus';
import StimulusControllerResolver, { createViteGlobResolver } from 'stimulus-controller-resolver';

const application = Application.start();

const controllers = import.meta.glob('./controllers/**/*_controller.{js,jsx}');
const paths = createViteGlobResolver(controllers);

// Wrap the resolver function to filter out blank controller names
const resolverFn = async (controllerName) => {
  // Skip resolution for blank controller names.
  if (!controllerName.trim()) { return undefined; }

  // Delegate to the original resolver function for valid controller names
  return paths(controllerName);
};

StimulusControllerResolver.install(application, resolverFn);
